/* Directive */
var VueDirective = {
	"tooltip" : {
		bind(el, binding) {
			if(!$.fn.tooltip) return;
			var text = typeof binding.value == "string" ? binding.value : $(el).attr('title');
			var options = {
				html: true,
				placement: 'auto',
				title: text,
				trigger: 'hover',
			}
			if(typeof binding.value == "object") options = Object.assign(options, binding.value)
			$(el).tooltip(options);
		},
		componentUpdated(el, binding) {
			if(!$.fn.tooltip) return;
			$(el).tooltip('dispose')
			var text = typeof binding.value == "string" ? binding.value : $(el).attr('title');
			var options = {
				html: true,
				placement: 'auto',
				title: text,
				trigger: 'hover',
			}
			if(typeof binding.value == "object") options = Object.assign(options, binding.value)
			$(el).tooltip(options)
		},
		unbind(el){
			if(!$.fn.tooltip) return;
			$(el).tooltip('dispose')	
		}
	}
}

export default VueDirective