import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [{
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "how-to/:id?",
        name: "BoPanduan",
        component: () =>
            import ("@backend/Help.vue")
    },
    {
        path: "api/:id?",
        name: "MrApi",
        component: () =>
            import("@backend/MrApi.vue")
    },
    {
        path: "service/:id?",
        name: "MrService",
        component: () =>
            import("@backend/MrService.vue")
    },
    {
        path: "management-quota/:id?",
        name: "AppManagementQuota",
        component: () =>
            import("@backend/AppManagementQuota.vue")
    },
    {
        path: "client/:id?",
        name: "MrClient",
        component: () =>
            import("@backend/MrClient.vue")
    },
    {
        path: "report/:id?",
        name: "AppRequest",
        component: () =>
            import("@backend/AppRequest.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    // 404
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data